import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import Layout from "../../shared/components/Layout";
import CustomSheetDetails from "./CustomSheetDetails";
import CustomSheets from "./CustomSheets";
import StandardSectionDetails from "./StandardSectionDetails";
import StandardSections from "./StandardSections";
import Submissions from "./Submissions";
import Users from "./Users";

// ----------------------------------------------------------------------------

interface LoggedAppProps {
  className?: string;
}

const LoggedApp: FC<LoggedAppProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <Layout className={`LoggedApp ${props.className}`}>
      <Routes>
        <Route path="/" element={<Users />} />
        <Route path="/eserciziario" element={<StandardSections />} />
        <Route path="/eserciziario/:id" element={<StandardSectionDetails />} />
        <Route path="/schede" element={<CustomSheets />} />
        <Route path="/schede/:id" element={<CustomSheetDetails />} />
        <Route path="/compilazioni" element={<Submissions />} />
      </Routes>
    </Layout>
  );
};

// ----------------------------------------------------------------------------

export default LoggedApp;
