import { Input, message } from "antd";
import React from "react";
import { useState } from "react";

import { useAuth } from "../../shared/AppState/auth";
import { login } from "../../shared/api";
import AppButton from "../../shared/components/AppButton";

// ----------------------------------------------------------------------------

function AuthPage(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login: dispatchLogin } = useAuth();

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function handleLogin(e) {
    e.preventDefault();
    try {
      const { user, token } = await login(email, password);
      if (user.role === "admin") {
        dispatchLogin(user, token);
      } else {
        message.error(
          "Solo gli amministratori possono usare questa interfaccia"
        );
      }
    } catch (e) {
      console.log(e);
      message.error("Credenziali rifiutate");
    }
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <form
      onSubmit={handleLogin}
      className={`${className} w-screen h-screen flex items-center justify-center`}
    >
      <div className="card w-[400px] p-3 rounded-lg flex flex-col items-center justify-center">
        <img
          className="block w-[170px] h-[170px] object-contain"
          src="/images/logo.svg"
        ></img>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-2"
          placeholder="Email"
        />
        <Input
          value={password}
          placeholder="Password"
          type={"password"}
          onChange={(e) => setPassword(e.target.value)}
          className="mb-2"
        />
        <AppButton role="primary" className="" type="submit">
          Login
        </AppButton>
      </div>
    </form>
  );
}

export default AuthPage;
