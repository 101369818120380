import React, { FC } from "react";

import { Exercise, StandardSection, Task } from "../../api/types";
import { hasSameKey } from "../../toolkit";
import AppSelect from "../FormItems/AppSelect";
import HtmlEditor from "../FormItems/HtmlEditor";
import Choice from "./Variants/Choice";
import File from "./Variants/File";
import Fill from "./Variants/Fill";
import Open from "./Variants/Open";
import Satzfelder from "./Variants/Satzfelder";

// ----------------------------------------------------------------------------

interface TaskEditorProps {
  className?: string;
  task: Task;
  index: number;
  exerciseIndex: number;
  exercise: Exercise;
  getFieldProps: (field: string) => any;
  setValues: (changer: (prevValues: any) => any) => void;
}

const TaskEditor: FC<TaskEditorProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { task, index, getFieldProps, setValues, exerciseIndex, exercise } =
    props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function updateTask(newTask) {
    setValues((old) => {
      const newValues = { ...old };
      newValues.exercises = newValues.exercises.map((iterExercise) => {
        if (hasSameKey(iterExercise, exercise)) {
          return {
            ...iterExercise,
            tasks: iterExercise.tasks.map((iterTask) => {
              if (hasSameKey(iterTask, task)) {
                return newTask;
              }
              return iterTask;
            }),
          };
        }
        return iterExercise;
      });
      return newValues;
    });
  }

  function handleTypeChange(newType) {
    const newDefaultAnswer =
      newType === "fill" || newType === "SF" ? ["", "", "", "", "", ""] : "";
    updateTask({ ...task, type: newType, rightAnswer: newDefaultAnswer });
  }

  function renderGenericSelectors() {
    return (
      <div className="">
        <HtmlEditor
          {...getFieldProps(`${baseTarget}.task`)}
          label="Testo della consegna"
          className="w-[500px] mb-2"
        />
        <AppSelect
          // {...getFieldProps(`${baseTarget}.type`)}
          value={task.type}
          onChange={handleTypeChange}
          onBlur={() => {}}
          label="Tipologia"
          className="!w-[500px] mb-2"
          options={[
            { label: "Domanda aperta", value: "open" },
            { label: "Scelta multipla", value: "m_choice" },
            { label: "Satzfelderanalyse", value: "SF" },
            { label: "Domanda con upload di file", value: "file" },
            { label: "Domanda a completamento", value: "fill" },
          ]}
        />
      </div>
    );
  }

  function renderTask() {
    const VariantProps = {
      getFieldProps,
      setValues,
      task,
      index,
      exerciseIndex,
      updateTask,
    };

    switch (task.type) {
      case "open":
        return <Open {...VariantProps} />;
      case "m_choice":
        return <Choice {...VariantProps} />;
      case "SF":
        return <Satzfelder {...VariantProps} />;
      case "fill":
        return <Fill {...VariantProps} />;
      case "file":
        return <File {...VariantProps} />;
      default:
        return (
          <div className="">
            Tipologia di task non ancora abilitata alla modifica
          </div>
        );
    }
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const baseTarget = `exercises[${exerciseIndex}].tasks[${index}]`;

  return (
    <div className={`${props.className}`}>
      {renderGenericSelectors()}
      {renderTask()}
    </div>
  );
};

// ----------------------------------------------------------------------------

export default TaskEditor;
