import React, { FC } from "react";

// ----------------------------------------------------------------------------

interface ValidationMessageProps {
  className?: string;
}

const ValidationMessage: FC<ValidationMessageProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  if (!props.children) {
    return <div className=""></div>;
  }

  return (
    <span
      className={`${props.className} block text-sm font-bold text-error mt-1`}
    >
      {props.children}
    </span>
  );
};

// ----------------------------------------------------------------------------

export default ValidationMessage;
