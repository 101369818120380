import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import urljoin from "url-join";

import { useAuth } from "../../../shared/AppState/auth";
import { getFrontEndUrl, getSubmissions } from "../../../shared/api";
import { PaginatedSubmissions, Submission } from "../../../shared/api/types";
import AppTable from "../../../shared/components/AppTable";
import PageTitle from "../../../shared/components/Typography/PageTitle";
import SubmissionFilters, { SubmissionQueryFilters } from "./SubmissionFilters";

// ----------------------------------------------------------------------------

interface SubmissionsProps {
  className?: string;
}

const Submissions: FC<SubmissionsProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [filters, setFilters] = useState<SubmissionQueryFilters>({
    type: "off",
  });

  const { token } = useAuth();

  const [page, setPage] = useState(1);

  const { data: submissions, isLoading } = useQuery<PaginatedSubmissions>(
    ["submissions", filters, page, token],
    async () => await getSubmissions(token, filters, page)
  );

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handlePreview(row: Submission) {
    const { _id } = row;
    const url = urljoin(getFrontEndUrl(), `/compilazione/${_id}`);
    window.open(url, "__blank");
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <PageTitle>Compilazioni</PageTitle>
      <SubmissionFilters
        className="mb-3"
        filters={filters}
        onFilter={setFilters}
      />
      <AppTable<Submission>
        dataSource={submissions?.data}
        columns={getColumns()}
        rowKey={(i) => i._id ?? ""}
        onView={(row) => handlePreview(row)}
        loading={isLoading}
        pagination={{
          current: page,
          total: submissions?.count,
          showSizeChanger: false,
          pageSize: 20,
        }}
        onChange={(pagination) =>
          pagination.current && setPage(pagination.current)
        }
      />
    </div>
  );
};

// ----------------------------------------------------------------------------

export default Submissions;

function getColumns(): ColumnsType<Submission> {
  return [
    { title: "Utente", key: "user", render: (row) => row?.user?.email },
    {
      title: "Tipologia",
      key: "type",
      dataIndex: "type",
      render: (type) => getTypeName(type),
    },
    {
      title: "Data d'inizio",
      dataIndex: "startedAt",
      key: "start",
      render: (start) => dayjs(start).format("D/M/YY HH:mm"),
    },
  ];
}

function getTypeName(type) {
  const MAP = {
    sheet: "Scheda",
    standard: "Eserciziario",
  };

  return MAP[type] ?? type;
}
