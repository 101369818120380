import { Dropdown } from "antd";
import React, { FC, useState } from "react";

import AppButton from "../../../../shared/components/AppButton";
import AppInput from "../../../../shared/components/FormItems/AppInput";
import AppSelect from "../../../../shared/components/FormItems/AppSelect";

// ----------------------------------------------------------------------------

interface UsersFiltersProps {
  className?: string;
  onFilter: (filters: { [key: string]: any }) => void;
}

const UsersFilters: FC<UsersFiltersProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------
  const { onFilter } = props;
  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [filters, setFilters] = useState<{ [key: string]: any }>({
    activeStatus: 1,
  });

  const [visible, setVisible] = useState(false);

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function updateFilter(filter) {
    return (value) => {
      setFilters({ ...filters, [filter]: value });
    };
  }

  function handleConfirm(e) {
    e.preventDefault();
    onFilter(filters);
    setVisible(false);
  }

  function renderBody() {
    return (
      <form
        onSubmit={handleConfirm}
        className="w-full p-2 bg-white shadow-lg rounded"
      >
        <AppInput
          className="w-[300px] mb-2"
          label="Indirizzo e-mail"
          onChange={updateFilter("email")}
          value={filters.email ?? ""}
        />
        <AppSelect
          label="Stato di attivazione"
          value={filters.activeStatus}
          className="mb-2"
          onChange={updateFilter("activeStatus")}
          options={[
            { label: "Tutti gli utenti", value: "off" },
            { label: "Solo utenti con indirizzo confermato", value: 1 },
            { label: "Solo utenti con indirizzo non confermato", value: 0 },
          ]}
        />
        <AppSelect
          label="Ruolo"
          value={filters.role}
          onChange={updateFilter("role")}
          options={[
            { label: "Amministratore", value: "admin" },
            { label: "Tutor", value: "tutor" },
            { label: "Utente", value: "user" },
          ]}
          className="mb-3"
        />
        <AppButton role="primary" type="submit">
          Applica filtri
        </AppButton>
      </form>
    );
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <Dropdown
        visible={visible}
        onVisibleChange={setVisible}
        overlay={renderBody()}
        trigger={["click"]}
      >
        <div
          className={[
            "w-[320px] h-[40px] rounded border-1 border-black",
            "flex items-center justify-center cursor-pointer",
          ].join(" ")}
        >
          <span className="text-[16px] font-medium">Filtra utenti</span>
        </div>
      </Dropdown>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default UsersFilters;
