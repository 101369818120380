import React, { FC } from "react";
import { AiOutlinePlus as Plus } from "react-icons/ai";

import AppButton from "../AppButton";

// ----------------------------------------------------------------------------

interface NewResourceButtonProps {
  className?: string;
  onClick?: () => void;
}

const NewResourceButton: FC<NewResourceButtonProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { onClick, className } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <AppButton
      onClick={onClick}
      role="secondary"
      className={[
        className,
        `w-[40px] h-[40px] mb-3 !border-1 flex items-center justify-center`,
      ].join(" ")}
    >
      <Plus className="w-[20px] h-[20px]" />
    </AppButton>
  );
};

// ----------------------------------------------------------------------------

export default NewResourceButton;
