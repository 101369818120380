import React, { FC } from "react";

// ----------------------------------------------------------------------------

interface PageTitleProps {
  className?: string;
}

const PageTitle: FC<PageTitleProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <h1 className={`${props.className} text-xl font-medium mb-3`}>
      {props.children}
    </h1>
  );
};

// ----------------------------------------------------------------------------

export default PageTitle;
