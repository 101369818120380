import { message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { useAuth } from "../../../shared/AppState/auth";
import {
  createStandardSection,
  getSection,
  updateStandardSection,
  uploadFile,
} from "../../../shared/api";
import { StandardSection } from "../../../shared/api/types";
import AppButton from "../../../shared/components/AppButton";
import PageTitle from "../../../shared/components/Typography/PageTitle";
import useFormHandler from "../../../shared/hooks/useFormHandler";
import { asyncMap } from "../../../shared/toolkit";
import StandardSectionExercises from "./Sections/Exercises";
import StandardSectionGeneral from "./Sections/General";

// ----------------------------------------------------------------------------

enum Page {
  GENERAL,
  EXERCISES,
}
interface StandardSectionDetailsProps {
  className?: string;
}

const StandardSectionDetails: FC<StandardSectionDetailsProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const { token } = useAuth();

  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;

  const isNewSection = id === "crea";

  const { data: section, refetch } = useQuery<StandardSection>(
    ["standard-section", id],
    async () => getSection(id, token),
    { enabled: !!(id && !isNewSection) }
  );

  const initialValues: StandardSection = {
    title: "",
    description: "",
    color: "",
    exercises: [],
  };

  const [loading, setLoading] = useState(false);

  const msg = "Campo obbligatorio";

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(msg),
    description: Yup.string().required(msg),
    color: Yup.string(),
    exercises: Yup.array().of(
      Yup.object().shape({
        mainTask: Yup.string().required(msg),
        tasks: Yup.array().of(
          Yup.object().shape({
            task: Yup.string().required(msg),
            type: Yup.string().required(msg),
            rightAnswer: Yup.mixed().when("type", {
              is: (type) => type === "fill" || type === "SF" || type === "file",
              then: Yup.mixed().when("type", {
                is: (type) => type === "fill" || type === "SF",
                then: Yup.array(),
                otherwise: Yup.mixed()
                  .nullable()
                  .required("Il file di soluzione è obbligatorio"),
              }),
              otherwise: Yup.string().required(msg),
            }),
          })
        ),
      })
    ),
  });

  const { getFieldProps, handleSubmit, values, bulkChange } =
    useFormHandler<StandardSection>({
      initialValues,
      validationSchema,
      onSubmit: handleConfirm,
      onSubmitWithErrorsMessage: handleSubmitWithErrors,
    });

  const [page, setPage] = useState<Page>(Page.GENERAL);

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (section && !isNewSection) {
      const mappedValues: StandardSection = {
        ...section,
        exercises: section.exercises.map((exe) => {
          return {
            ...exe,
            tasks: exe.tasks.map((task) => {
              if (task.type === "fill" || task.type === "SF") {
                return { ...task, rightAnswer: JSON.parse(task.rightAnswer) };
              }
              return task;
            }),
          };
        }),
      };

      bulkChange({ ...values, ...mappedValues });
    }
  }, [section]);

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function setValues(
    changer: (prevValues: StandardSection) => StandardSection
  ) {
    const newValues = changer(values);

    bulkChange(newValues);
  }

  async function buildPayload(values: StandardSection) {
    return {
      ...values,
      exercises: await asyncMap(values.exercises, async (exercise) => {
        return {
          ...exercise,
          tasks: await asyncMap(exercise.tasks, async (task) => {
            if (task.type === "file") {
              const { rightAnswer } = task;
              let url;
              if (rightAnswer instanceof File) {
                const { url: remoteUrl } = await uploadFile(rightAnswer, token);
                url = remoteUrl;
              } else {
                url = rightAnswer;
              }
              return { ...task, rightAnswer: url };
            } else if (["fill", "SF"].includes(task.type)) {
              return { ...task, rightAnswer: JSON.stringify(task.rightAnswer) };
            } else {
              return task;
            }
          }),
        };
      }),
    };
  }

  async function handleConfirm(values: StandardSection) {
    setLoading(true);
    try {
      const payload = await buildPayload(values);
      if (values._id) {
        await updateStandardSection(values._id, payload, token);
      } else {
        await createStandardSection(payload, token);
      }
      refetch();
      message.success("Operazione completata con successo");
      navigate("/eserciziario");
    } catch (e) {
      console.log(e);
      message.error("Ci sono stati errori durante il salvataggio");
    }
    setLoading(false);
  }

  function handleSubmitWithErrors() {
    message.error("Ci sono errori nella compilazione");
  }

  function renderSelector() {
    const tile = (name: string, targetPage: Page) => {
      const isActive = page === targetPage;
      return (
        <div
          className={[
            "flex-1 flex items-center justify-center",
            "first:border-r-2 first:border-r-primary-900",
            "cursor-pointer transition-all text-md",
            "bg-white",
            isActive && "!bg-primary-900 text-white",
          ].join(" ")}
          onClick={() => setPage(targetPage)}
        >
          {name}
        </div>
      );
    };

    return (
      <div
        className={[
          "w-full flex flex-row rounded border-1 border-primary-900 bg-primary-900 h-[40px]",
          "overflow-hidden mb-3",
        ].join(" ")}
      >
        {tile("Informazioni generali", Page.GENERAL)}
        {tile("Esercizi", Page.EXERCISES)}
      </div>
    );
  }

  function renderPage() {
    if (page === Page.GENERAL) {
      return (
        <StandardSectionGeneral getFieldProps={getFieldProps} values={values} />
      );
    } else if (page === Page.EXERCISES) {
      return (
        <StandardSectionExercises
          getFieldProps={getFieldProps}
          values={values}
          setValues={setValues}
        />
      );
    }
    return <div className=""></div>;
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <div className="w-full flex flex-row items-center justify-between mb-3">
        <PageTitle className="!mb-[0px]">
          {isNewSection ? "Crea nuova sezione" : section?.title}
        </PageTitle>
        <AppButton
          loading={loading}
          className="w-[100px]"
          role="success"
          onClick={handleSubmit}
        >
          Salva
        </AppButton>
      </div>
      {renderSelector()}
      {renderPage()}
    </div>
  );
};

// ----------------------------------------------------------------------------

export default StandardSectionDetails;
