import "./index.css";

import React, { FC, useMemo } from "react";
import { AiOutlinePlus, AiOutlinePlus as Plus } from "react-icons/ai";

import AppButton from "../../AppButton";
import FieldLabel from "../FieldLabel";

// ----------------------------------------------------------------------------

interface FileInputProps {
  className?: string;
  value?: string | File;
  onUpload: (file: File | undefined) => void;
  label?: string;
}

const FileInput: FC<FileInputProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------
  const { value, onUpload, label } = props;
  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const url = useMemo(() => {
    if (value) {
      if (typeof value === "string") {
        return value;
      }
      return URL.createObjectURL(value);
    }
    return undefined;
  }, [value]);

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function renderSelector() {
    return (
      <div
        className={[
          "w-[120px] h-[120px] border-dashed border-1 border-grey-500 relative",
          "flex items-center justify-center cursor-pointer",
          "mt-1 ",
        ].join(" ")}
      >
        <AiOutlinePlus className="w-4 h-4 fill-grey-500" />
        <input
          type={"file"}
          className="absolute w-full h-full opacity-0 cursor-pointer"
          onChange={handleUpload}
        />
      </div>
    );
  }

  function renderPreview() {
    if (url) {
      return (
        <div className="flex flex-col">
          <div className="flex items-center justify-center bg-grey-200 w-[150px] h-[150px]">
            <img
              src={url}
              alt=""
              className="block w-[150px] h-[150px] object-contain"
            />
          </div>
          <AppButton
            className={["w-[150px] h-[40px] mt-2"].join(" ")}
            onClick={handleImageDelete}
            role="delete"
          >
            Elimina file
          </AppButton>
        </div>
      );
    }
    return <div className=""></div>;
  }

  function handleUpload(e) {
    const file = e.target.files[0];
    onUpload && onUpload(file);
  }

  function handleImageDelete() {
    onUpload(undefined);
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className} pb-2`}>
      <FieldLabel className="!mb-2">{label}</FieldLabel>
      {url ? renderPreview() : renderSelector()}
    </div>
  );
};

// ----------------------------------------------------------------------------

export default FileInput;
