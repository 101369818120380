import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import RoutedApp from "./RoutedApp";

const Client = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={Client}>
      <RoutedApp />;
    </QueryClientProvider>
  );
}
