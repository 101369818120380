export const asyncMap = async <T, G>(
  array: T[],
  callback: (item: T, index: number, array: T[]) => Promise<G>
): Promise<G[]> => {
  const ret: G[] = [];
  for (let i = 0; i < array.length; i++) {
    const result: G = await callback(array[i], i, array);
    ret.push(result);
  }
  return ret;
};

export const idExtractor = (item) => item?._id ?? item?.tempId;

export const hasSameKey = (item1: any, item2: any, extractor = idExtractor) => {
  return extractor(item1) === extractor(item2);
};
