import React, { FC } from "react";

// ----------------------------------------------------------------------------

interface FieldLabelProps {
  className?: string;
}

const FieldLabel: FC<FieldLabelProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <span className={`${props.className} block text-text-muted mb-1`}>
      {props.children}
    </span>
  );
};

// ----------------------------------------------------------------------------

export default FieldLabel;
