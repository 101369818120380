import React, { FC } from "react";
import { AiOutlinePoweroff } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../../AppState/auth";

// ----------------------------------------------------------------------------

interface AppMenuProps {
  className?: string;
}

const AppMenu: FC<AppMenuProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { logout } = useAuth();

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleLogout() {
    logout();
    navigate("/");
  }

  function doNaviagte(href) {
    return () => navigate(href);
  }

  function getIsActive(path, strict) {
    return strict ? path === pathname : new RegExp(path).test(pathname);
  }

  function renderMenuItem(label, href, strict = true) {
    const isActive = getIsActive(href, strict);
    return (
      <div
        className={[
          "flex items-center justify-center w-full h-[40px] rounded-lg",
          "mb-3",
          "transition-all cursor-pointer",
          "hover:bg-primary-900 hover:text-white",
          isActive && "bg-primary-900 text-white",
        ].join(" ")}
        onClick={doNaviagte(href)}
      >
        <span className={["text-base font-medium"].join(" ")}>{label}</span>
      </div>
    );
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div
      className={`${props.className} p-2 w-full h-full flex flex-col items-center justify-start`}
    >
      <img
        className="block w-3/4 h-auto object-contain mt-2 mb-4"
        src="/images/logo.svg"
      ></img>
      <div className="mt-4 w-full">
        {renderMenuItem("Utenti", "/")}
        {renderMenuItem("Esercizi", "/eserciziario", false)}
        {renderMenuItem("Schede", "/schede")}
        {renderMenuItem("Compilazioni", "/compilazioni")}
      </div>
      <div
        className="mt-auto flex items-center cursor-pointer"
        onClick={handleLogout}
      >
        <AiOutlinePoweroff className="fill-text-muted" />
        <span className="block ml-2 text-text-muted">Logout</span>
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default AppMenu;
