import React, { FC, useState } from "react";
import {
  AiOutlineArrowUp as Arrow,
  AiOutlineDelete as Delete,
} from "react-icons/ai";

import { StandardSection, Task } from "../../../api/types";
import AppInput from "../../FormItems/AppInput";
import FieldLabel from "../../FormItems/FieldLabel";

// ----------------------------------------------------------------------------

interface ChoiceProps {
  className?: string;
  task: Task;
  getFieldProps: (field: string) => any;
  setValues: (
    changer: (prevValues: StandardSection) => StandardSection
  ) => void;
  index: number;
  exerciseIndex: number;
  updateTask: (task: Task) => void;
}

const Choice: FC<ChoiceProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { getFieldProps, index, exerciseIndex, updateTask, task } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [newAnswer, setNewAnswer] = useState("");

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleAddWrongAnswer(e) {
    e.preventDefault();
    if (newAnswer && !task.wrongAnswers?.find((ans) => ans === newAnswer)) {
      const newAnswers = [...(task.wrongAnswers ?? []), newAnswer];
      updateTask({ ...task, wrongAnswers: newAnswers });
      setNewAnswer("");
    }
  }

  function handleRemoveWrongAnswer(answer: string) {
    const newAnswers = task.wrongAnswers?.filter((ans) => answer !== ans);
    updateTask({ ...task, wrongAnswers: newAnswers ?? [] });
  }

  function renderWrongAnswerSelector() {
    return (
      <div className="w-[500px] border-l-1 border-l-primary-500 pl-2">
        <FieldLabel>Risposte errate</FieldLabel>
        {task.wrongAnswers?.map((ans) => (
          <div key={ans} className="flex flex-row items-center mb-1">
            <div
              className="cursor-pointer flex items-center justify-center mr-2"
              onClick={() => handleRemoveWrongAnswer(ans)}
            >
              <Delete className="fill-error w-3 h-3"></Delete>
            </div>
            <span className="block">{ans}</span>
          </div>
        ))}
        <form
          onSubmit={handleAddWrongAnswer}
          className={[
            "flex flex-row items-center border-b",
            "border-primary-900 w-fit pb-1 mt-3",
          ].join(" ")}
        >
          <div className="mr-2 w-[300px]">
            <input
              value={newAnswer}
              onChange={(e) => setNewAnswer(e.target.value)}
              className="border-none border-0 outline-none w-full block"
              placeholder="Nuova risposta"
            />
          </div>
          <div
            className={[
              "rounded-[50%] w-[20px] h-[20px] bg-primary-900",
              "flex items-center justify-center",
            ].join(" ")}
          >
            <Arrow className="fill-white h-[15px] w-[15px]" />
          </div>
        </form>
      </div>
    );
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------
  const target = `exercises.${exerciseIndex}.tasks.${index}`;

  return (
    <div className={`${props.className}`}>
      <AppInput
        {...getFieldProps(`${target}.rightAnswer`)}
        label="Risposta corretta"
        className="w-[500px] mb-3 max-w-unset"
      />
      {renderWrongAnswerSelector()}
    </div>
  );
};

// ----------------------------------------------------------------------------

export default Choice;
