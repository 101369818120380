import { Tabs } from "antd";
import React, { FC, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  AiOutlineArrowUp as Arrow,
  AiOutlineDelete as Delete,
} from "react-icons/ai";
import { MdDragHandle as DragHandle } from "react-icons/md";

import {
  CustomSheet,
  Exercise,
  Task,
} from "../../../../../../shared/api/types";
import BottomSheet from "../../../../../../shared/components/BottomSheet";
import HtmlEditor from "../../../../../../shared/components/FormItems/HtmlEditor";
import TaskEditor from "../../../../../../shared/components/TaskEditor";
import { hasSameKey, idExtractor } from "../../../../../../shared/toolkit";

const { TabPane } = Tabs;

// ----------------------------------------------------------------------------

interface ExerciseBottomSheetProps {
  className?: string;
  exercise?: Exercise;
  index?: number;
  open: boolean;
  onClose: () => void;
  getFieldProps: (field: string) => any;
  setValues: (changer: (prevValues: CustomSheet) => CustomSheet) => void;
}

const ExerciseBottomSheet: FC<ExerciseBottomSheetProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const {
    open,
    onClose,
    getFieldProps,
    index: exerciseIndex,
    exercise,
    setValues,
  } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [selectedTask, setSelectedTask] = useState<{
    task: Task;
    index: number;
  } | null>(null);

  const [newTask, setNewTask] = useState("");

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function updateExercise(newExercise: Exercise) {
    if (exercise) {
      setValues((old) => {
        return {
          ...old,
          exercises: old.exercises.map((exe) => {
            if (hasSameKey(exe, exercise)) {
              return newExercise;
            }
            return exe;
          }),
        };
      });
    }
  }

  function handleTaskSelected(task, index) {
    setSelectedTask({ task, index });
  }

  function handleAddTask(e) {
    e.preventDefault();
    exercise &&
      updateExercise({
        ...exercise,
        tasks: [
          ...(exercise.tasks ?? []),
          {
            tempId: Math.random().toString(),
            task: newTask,
            rightAnswer: "",
            type: "open",
          },
        ],
      });
    setNewTask("");
  }

  function handleRemoveTask(task: Task) {
    exercise &&
      updateExercise({
        ...exercise,
        tasks: exercise.tasks.filter((t) => !hasSameKey(t, task)),
      });
  }

  function handleOrderTasks(data) {
    const { source, destination } = data;
    if (source && destination && exercise) {
      const { index: sourceIndex } = source;
      const { index: destinationIndex } = destination;

      const tasks = [...(exercise?.tasks ?? [])];
      const targetElement = exercise.tasks[sourceIndex];

      tasks.splice(sourceIndex, 1);
      tasks.splice(destinationIndex, 0, targetElement);

      updateExercise({ ...exercise, tasks });
    }
  }

  function renderAddTask() {
    return (
      <form
        onSubmit={handleAddTask}
        className={[
          "flex flex-row items-center border-b",
          "border-primary-900 w-full pb-1 mt-3",
        ].join(" ")}
      >
        <div className="mr-2 w-[300px]">
          <input
            value={newTask}
            onChange={(e) => setNewTask(e.target.value)}
            className="border-none border-0 outline-none w-full block text-sm"
            placeholder="Titolo nuovo task"
          />
        </div>

        <div
          className={[
            "rounded-[50%] min-w-[20px] min-h-[20px] bg-primary-900",
            "flex items-center justify-center cursor-pointer",
          ].join(" ")}
        >
          <Arrow className="fill-white h-[15px] w-[15px]" />
        </div>
      </form>
    );
  }

  function renderTaskList() {
    return (
      <div className="w-[300px] py-2 pl-3 border-l-1 border-l-grey-500">
        <DragDropContext onDragEnd={handleOrderTasks}>
          <Droppable droppableId="tasks-droppable-area">
            {(provided) => {
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className=""
                >
                  {exercise?.tasks?.map((task, index) => {
                    const isActive = hasSameKey(selectedTask?.task, task);
                    return (
                      <Draggable
                        index={index}
                        key={idExtractor(task)}
                        draggableId={idExtractor(task)}
                      >
                        {(dragProvided) => {
                          return (
                            <div
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              className={[
                                "mb-2 cursor-pointer transition-all",
                                "flex flex-row items-center",
                                isActive && "text-primary-900 underline",
                              ].join(" ")}
                              onClick={() => handleTaskSelected(task, index)}
                            >
                              <div
                                className="mr-1"
                                {...dragProvided.dragHandleProps}
                              >
                                <DragHandle className="w-[20px] h-[20px]" />
                              </div>
                              <div
                                className="flex items-center justify-center mr-1"
                                onClick={() => handleRemoveTask(task)}
                              >
                                <Delete className="w-[20px] h-[20px] fill-error" />
                              </div>
                              <span
                                className={["text-sm block"].join(" ")}
                                dangerouslySetInnerHTML={{
                                  __html: task.task,
                                }}
                              ></span>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
        {renderAddTask()}
      </div>
    );
  }

  function renderTaskBody() {
    if (selectedTask && (exerciseIndex || exerciseIndex === 0) && exercise) {
      return (
        <div className="">
          {currentlySelectedTask && (
            <TaskEditor
              exerciseIndex={exerciseIndex}
              exercise={exercise}
              task={currentlySelectedTask}
              index={selectedTask.index}
              getFieldProps={getFieldProps}
              setValues={setValues}
              key={idExtractor(currentlySelectedTask)}
            />
          )}
        </div>
      );
    }
    return <div className=""></div>;
  }

  function renderTaskPanel() {
    return (
      <div
        className={["w-full flex flex-row items-start justify-between"].join(
          " "
        )}
      >
        {renderTaskBody()}
        {renderTaskList()}
      </div>
    );
  }

  function renderExerciseMetaSelectors() {
    return (
      <div className="">
        <HtmlEditor
          {...getFieldProps(`exercises.[${exerciseIndex}].mainTask`)}
          label="Consegna principale"
          className="w-[500px]"
        ></HtmlEditor>
      </div>
    );
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const currentlySelectedTask = exercise?.tasks.find((iterTask) =>
    hasSameKey(iterTask, selectedTask?.task)
  );

  return (
    <BottomSheet
      title="Editor esercizio"
      open={open}
      onConfirm={onClose}
      className={`${props.className}`}
    >
      <Tabs>
        <TabPane tab="Dettagli esercizio" key="general" tabKey="general">
          {open && renderExerciseMetaSelectors()}
        </TabPane>
        <TabPane tab="Consegne" key="tasks">
          {renderTaskPanel()}
        </TabPane>
      </Tabs>
    </BottomSheet>
  );
};

// ----------------------------------------------------------------------------

export default ExerciseBottomSheet;
