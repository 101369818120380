import React, { FC, useEffect } from "react";
import { AiOutlineClose as Close } from "react-icons/ai";

import AppButton from "../AppButton";
import { useScrollContext } from "../Layout";

// ----------------------------------------------------------------------------

interface BottomSheetProps {
  className?: string;
  open: boolean;
  onClose?: () => void;
  title: string;
  onConfirm?: () => void;
}

const BottomSheet: FC<BottomSheetProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { open, title, onClose, onConfirm } = props;

  const scrollRef = useScrollContext();

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  useEffect(() => {
    if (scrollRef?.current) {
      if (open) {
        scrollRef.current.style.overflowY = "hidden";
      } else {
        scrollRef.current.style.overflowY = "auto";
      }
    }
  }, [open]);

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div
      className={[
        `${props.className} h-[80vh] max-h-[80vh] w-screen`,
        "transition-all overflow-hidden",
        "bg-white",
        open && "",
      ].join(" ")}
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        top: open ? "20vh" : "100vh",
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
        boxShadow: "0px 0px 24px rgba(0,0,0,0.3)",
      }}
    >
      <div className="p-3 flex flex-col min-h-full max-h-full overflow-y-auto overflow-x-hidden">
        <div className="flex flex-row items-center justify-between">
          <h2 className="font-bold text-lg mb-2">{title}</h2>
          {onClose && (
            <div className="flex items-center justify-center" onClick={onClose}>
              <Close className="h-3 w-3 cursor-pointer" />
            </div>
          )}
        </div>
        <div className="w-full mb-3">{props.children}</div>
        <div className="flex flex-row items-center justify-end mt-auto">
          {onClose && (
            <AppButton onClick={onClose} role="delete" className="w-6 mr-2">
              Chiudi
            </AppButton>
          )}
          {onConfirm && (
            <AppButton onClick={onConfirm} role="secondary" className="w-6">
              Salva
            </AppButton>
          )}
        </div>
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default BottomSheet;
