import { DatePicker } from "antd";
import moment from "moment";
import React, { FC, useMemo } from "react";

import FieldLabel from "../FormItems/FieldLabel";

// ----------------------------------------------------------------------------

interface AppDatePickerProps {
  className?: string;
  value?: string;
  onChange?: (v: string | undefined) => void;
  onBlur?: any;
  label?: string;
  inputClass?: string;
}

const AppDatePicker: FC<AppDatePickerProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------
  const { onBlur, value, onChange, label, inputClass } = props;
  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const dateValue = useMemo(() => {
    return value ? moment(value) : null;
  }, [value]);

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleChange(date: moment.Moment) {
    onChange &&
      onChange(
        date
          ? date
              .set("hour", 0)
              .set("minute", 0)
              .set("millisecond", 0)
              .toISOString()
          : undefined
      );
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <FieldLabel>{label}</FieldLabel>
      <DatePicker
        value={dateValue}
        onChange={handleChange}
        onBlur={onBlur}
        className={`${inputClass}`}
      ></DatePicker>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default AppDatePicker;
