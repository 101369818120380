import { Popconfirm } from "antd";
import React from "react";
import { AiOutlineEdit as Edit } from "react-icons/ai";
import { AiOutlineDelete as Delete } from "react-icons/ai";
import { HiOutlineBookOpen as View } from "react-icons/hi";

// ----------------------------------------------------------------------------

interface TableActionsProps<T> {
  row: T;
  className?: string;
  onEdit?: (row: T) => void;
  onDelete?: (row: T) => void;
  onView?: (row: T) => void;
}

function TableActions<T>(props: TableActionsProps<T>) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { onEdit, onDelete, row, onView } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function renderView() {
    return (
      <div
        className="cursor-pointer w-3 h-3"
        onClick={() => onView && onView(row)}
      >
        <View className="w-full h-full stroke-black" />
      </div>
    );
  }

  function renderEdit() {
    return (
      <div
        className="cursor-pointer w-3 h-3"
        onClick={() => onEdit && onEdit(row)}
      >
        <Edit className="w-full h-full fill-primary-900" />
      </div>
    );
  }

  function renderDelete() {
    return (
      <Popconfirm
        title="Sei sicuro di voler eliminare questo esercizio?"
        onConfirm={() => onDelete && onDelete(row)}
        placement="left"
      >
        <div className="cursor-pointer w-3 h-3">
          <Delete className="w-full h-full fill-error" />
        </div>
      </Popconfirm>
    );
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className} flex flex-row`}>
      {onView && renderView()}
      <div className="w-2"></div>
      {onEdit && renderEdit()}
      <div className="w-2"></div>
      {onDelete && renderDelete()}
    </div>
  );
}

// ----------------------------------------------------------------------------

export default TableActions;
