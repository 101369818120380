import React, { FC } from "react";

import { CustomSheet } from "../../../../../shared/api/types";
import AppInput from "../../../../../shared/components/FormItems/AppInput";
import AppSelect from "../../../../../shared/components/FormItems/AppSelect";

// ----------------------------------------------------------------------------

interface CustomSheetGeneralProps {
  className?: string;
  values: CustomSheet;
  getFieldProps: (field: string) => any;
}

const CustomSheetGeneral: FC<CustomSheetGeneralProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------
  const { getFieldProps } = props;
  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <AppInput
        {...getFieldProps("title")}
        className="mb-2 w-300"
        label="Nome scheda"
      />
      <AppSelect
        {...getFieldProps("type")}
        className="mb-2 w-300"
        label="Tipologia"
        options={[
          { label: "Normale", value: "normal" },
          { label: "Esame passato", value: "exam" },
        ]}
      />
    </div>
  );
};

// ----------------------------------------------------------------------------

export default CustomSheetGeneral;
