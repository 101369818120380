import React from "react";
import { HashRouter as Router } from "react-router-dom";

import { useAuth } from "../shared/AppState/auth";
import AuthPage from "./AuthPage";
import LoggedApp from "./LoggedApp";

export default function RoutedApp() {
  const { token } = useAuth();
  return <Router>{token ? <LoggedApp /> : <AuthPage />}</Router>;
}
