import React, { FC } from "react";

import { StandardSection, Task } from "../../../api/types";
import FileInput from "../../FormItems/FileInput";

// ----------------------------------------------------------------------------

interface FileProps {
  className?: string;
  task: Task;
  getFieldProps: (field: string) => any;
  setValues: (
    changer: (prevValues: StandardSection) => StandardSection
  ) => void;
  index: number;
  exerciseIndex: number;
  updateTask: (task: Task) => void;
}

const File: FC<FileProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------
  const { updateTask, task } = props;
  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleUpload(file: File) {
    const newTask: Task = { ...task, rightAnswer: file };
    updateTask(newTask);
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <FileInput
        label="Carica file di soluzione esempio"
        value={task.rightAnswer}
        onUpload={handleUpload}
        className="mt-3"
      />
    </div>
  );
};

// ----------------------------------------------------------------------------

export default File;
