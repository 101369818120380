import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";

import React, { FC } from "react";
import ReactQuill from "react-quill";

import FieldLabel from "../FieldLabel";
import ValidationMessage from "../ValidationMessage";

// ----------------------------------------------------------------------------

interface HtmlEditorProps {
  className?: string;
  value?: string;
  label?: string;
  onChange?: (val: string) => void;
  onBlur?: () => void;
  error?: string;
}

const HtmlEditor: FC<HtmlEditorProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------
  const { label, value, onChange, onBlur, error } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleChange(value) {
    onChange && onChange(value);
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <FieldLabel>{label}</FieldLabel>
      <ReactQuill
        className="w-full"
        defaultValue={value}
        onBlur={onBlur}
        onChange={handleChange}
      />
      <ValidationMessage>{error}</ValidationMessage>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default HtmlEditor;
