import { Select } from "antd";
import React, { FC } from "react";

import FieldLabel from "../FieldLabel";
import ValidationMessage from "../ValidationMessage";

// ----------------------------------------------------------------------------

type Value = string | number | "off";

interface AppSelectProps {
  className?: string;
  label?: string;
  error?: string;
  value: Value | undefined;
  onChange: (v: Value) => void;
  onBlur?: () => void;
  options: { label: string; value: Value }[];
  allowClear?: boolean;

  filterOption?: (inputValue: string, option: any) => boolean;
}

const AppSelect: FC<AppSelectProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------
  const {
    value,
    onChange,
    onBlur,
    options,
    label,
    error,
    filterOption,
    allowClear,
  } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className} w-[300px]`}>
      <FieldLabel>{label}</FieldLabel>
      <Select
        filterOption={filterOption}
        showSearch={filterOption ? true : false}
        value={value}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        className="w-full"
        allowClear={allowClear}
      />
      <ValidationMessage>{error}</ValidationMessage>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default AppSelect;
