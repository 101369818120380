import React, { FC, MutableRefObject, useContext, useRef } from "react";

import AppMenu from "./Menu";

// ----------------------------------------------------------------------------

interface LayoutProps {
  className?: string;
}

const ScrollContext = React.createContext<MutableRefObject<
  HTMLDivElement | undefined
> | null>(null);

const Layout: FC<LayoutProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const scrollRef = useRef<any>();

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div
      className={`${props.className} w-screen h-screen flex flex-row overflow-hidden`}
    >
      <div className="left h-screen w-[250px] bg-grey-200">
        <AppMenu />
      </div>
      <div
        className="right flex-1 overflow-x-hidden overflow-y-auto"
        ref={scrollRef}
      >
        <ScrollContext.Provider value={scrollRef}>
          <main className="p-3">{props.children}</main>
        </ScrollContext.Provider>
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default Layout;

export const useScrollContext = () => useContext(ScrollContext);
