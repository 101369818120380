function encodeParams(params) {
  return Object.entries(params)
    .map(([key, value]: [string, string]) => {
      if (
        value !== null &&
        typeof value !== "undefined" &&
        value !== "off" &&
        value !== ""
      ) {
        if (Array.isArray(value)) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            value.join(",")
          )}`;
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      } else {
        return null;
      }
    })
    .filter((i) => i)
    .join("&");
}

export async function executeGETRequest(url, token, params?) {
  const query = params && encodeParams(params);
  const finalUrl = query ? `${url}?${query}` : url;
  const response = await fetch(finalUrl, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error(await response.text());
  }
}

export async function executeDELETERequest(url, token) {
  const finalUrl = url;
  const response = await fetch(finalUrl, {
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
  });
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error(await response.text());
  }
}

export async function executePOSTRequest(url, token, body, params?) {
  const query = params && encodeParams(params);
  const finalUrl = query ? `${url}?${query}` : url;
  const response = await fetch(finalUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    method: "POST",
  });
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error(await response.text());
  }
}

export async function executePUTRequest(url, token, body, params?) {
  const query = params && encodeParams(params);
  const finalUrl = query ? `${url}?${query}` : url;
  const response = await fetch(finalUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    method: "PUT",
  });
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error(await response.text());
  }
}

export async function executeFormDateRequest(url, token, body = {}, params?) {
  const query = params && encodeParams(params);
  const finalUrl = query ? `${url}?${query}` : url;
  const form = new FormData();
  Object.entries(body).forEach(([key, value]: [string, string]) =>
    form.append(key, value)
  );

  const response = await fetch(finalUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: form,
    method: "POST",
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error(await response.text());
  }
}
