import "./Fill.css";

import React, { FC } from "react";

import { StandardSection, Task } from "../../../api/types";
import FieldLabel from "../../FormItems/FieldLabel";
import HtmlEditor from "../../FormItems/HtmlEditor";

// ----------------------------------------------------------------------------

interface FillProps {
  className?: string;
  task: Task;
  getFieldProps: (field: string) => any;
  setValues: (
    changer: (prevValues: StandardSection) => StandardSection
  ) => void;
  index: number;
  exerciseIndex: number;
  updateTask: (task: Task) => void;
}

const Fill: FC<FillProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { getFieldProps, index, exerciseIndex, task } = props;
  const { gapped_text: gappedText = "" } = task;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function renderTextComplete() {
    const toRender = split.map((part, index) => {
      const { value, onChange, onBlur } = getFieldProps(
        `${target}.rightAnswer.${index}`
      );

      return (
        <span key={index} className="leading-7">
          <span
            dangerouslySetInnerHTML={{ __html: part }}
            className="inline"
          ></span>
          {index !== split.length - 1 && (
            <input
              value={value ?? ""}
              onChange={(e) => onChange(e.target.value)}
              onBlur={onBlur}
              className="inline-block border-1 border-grey-300 px-1 w-[150px] h-[25px]"
            />
          )}
        </span>
      );
    });
    return (
      <div className="w-[500px] mt-3 complete">
        <FieldLabel>Completa il testo</FieldLabel>
        {toRender}
      </div>
    );
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const target = `exercises.${exerciseIndex}.tasks.${index}`;
  const split = gappedText.replace(/\n/g, "<br />").split("_");

  return (
    <div className={`${props.className}`}>
      <HtmlEditor
        {...getFieldProps(`${target}.gapped_text`)}
        label="Testo presentato all'utente. Indicare la presenza di un campo di testo con un underscore (_)"
        className="w-[500px] mt-3"
      />
      {renderTextComplete()}
    </div>
  );
};

// ----------------------------------------------------------------------------

export default Fill;
