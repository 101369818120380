import { ColumnsType } from "antd/lib/table";
import React, { FC, useMemo, useState } from "react";
import { AiOutlinePlus as Plus } from "react-icons/ai";

import { Exercise, StandardSection } from "../../../../../shared/api/types";
import AppButton from "../../../../../shared/components/AppButton";
import AppTable from "../../../../../shared/components/AppTable";
import SearchBox from "../../../../../shared/components/FormItems/SearchBox";
import { hasSameKey } from "../../../../../shared/toolkit";
import ExerciseBottomSheet from "./ExerciseBottomSheet";

// ----------------------------------------------------------------------------

interface StandardSectionExercisesProps {
  className?: string;
  values: StandardSection;
  getFieldProps: (field: string) => any;
  setValues: (
    changer: (prevValues: StandardSection) => StandardSection
  ) => void;
}

const StandardSectionExercises: FC<StandardSectionExercisesProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { values, getFieldProps, setValues } = props;

  const { exercises } = values;

  const [selectedItem, setSelectedItem] = useState<{
    exercise: Exercise;
    index: number;
  } | null>(null);

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [exerciseSearch, setExerciseSearch] = useState("");
  const [taskSearch, setTaskSearch] = useState("");

  const filteredExercises = useMemo<Exercise[]>(() => {
    if (exercises) {
      if (taskSearch || exerciseSearch) {
        const exercisePat = new RegExp(exerciseSearch.toLowerCase(), "gm");

        const taskPat = new RegExp(taskSearch.toLowerCase(), "gm");
        return exercises.filter((exe) => {
          return (
            exercisePat.test(exe.mainTask.toLowerCase()) &&
            exe.tasks.some((task) => taskPat.test(task.task.toLowerCase()))
          );
        });
      }
      return exercises;
    }
    return [];
  }, [values, exerciseSearch, taskSearch]);

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleEdit(row: Exercise) {
    const index = exercises.findIndex((item) => hasSameKey(item, row));
    setSelectedItem({ exercise: row, index });
  }

  function handleAddExercise() {
    const newExercise: Exercise = {
      tempId: Math.random().toString(),
      mainTask: "Nuovo esercizio",
      tasks: [],
      code: "",
    };
    setValues((oldValues) => {
      return {
        ...oldValues,
        exercises: [newExercise, ...(oldValues.exercises ?? [])],
      };
    });
    setSelectedItem({ exercise: newExercise, index: 0 });
  }

  function handleDelete(row: Exercise) {
    setValues((old) => {
      return {
        ...old,
        exercises: old.exercises.filter((exe) => exe._id !== row._id),
      };
    });
  }

  function handleModalDismiss() {
    setSelectedItem(null);
  }

  function renderSearch() {
    return (
      <div className="flex items-center flex-row">
        <SearchBox
          value={exerciseSearch}
          onChange={setExerciseSearch}
          label="Ricerca consegna esercizio"
          className="mr-2"
        />
        <SearchBox
          value={taskSearch}
          onChange={setTaskSearch}
          label="Ricerca consegna task"
          className=""
        />
      </div>
    );
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      {renderSearch()}
      <div className="w-full h-[1px] bg-grey-200 my-3"></div>
      <AppButton
        onClick={handleAddExercise}
        role="secondary"
        className="w-[40px] h-[40px] mb-3 !border-1 flex items-center justify-center"
      >
        <Plus className="w-[20px] h-[20px]" />
      </AppButton>

      <AppTable
        dataSource={filteredExercises}
        columns={getColumns()}
        onEdit={handleEdit}
        onDelete={handleDelete}
        rowKey={(i) => i._id ?? ""}
      />
      <ExerciseBottomSheet
        open={!!selectedItem}
        exercise={exercises.find((exe) =>
          hasSameKey(exe, selectedItem?.exercise)
        )}
        index={selectedItem?.index}
        onClose={handleModalDismiss}
        setValues={setValues}
        getFieldProps={getFieldProps}
      />
    </div>
  );
};

// ----------------------------------------------------------------------------

export default StandardSectionExercises;

const getColumns: () => ColumnsType<Exercise> = () => {
  return [
    {
      title: "Consegna principale",
      key: "title",
      dataIndex: "mainTask",
      render: (text) => (
        <span dangerouslySetInnerHTML={{ __html: text }}></span>
      ),
    },
    {
      title: "Numero di sotto-task",
      key: "taskNum",
      dataIndex: "tasks",
      render: (tasks) => tasks?.length,
    },
  ];
};
