import { Input } from "antd";
import React, { FC } from "react";

import FieldLabel from "../FieldLabel";

const { Search } = Input;

// ----------------------------------------------------------------------------

interface SearchBoxProps {
  className?: string;
  label?: string;
  onSearch?: (v: string) => void;
  value?: string;
  onChange?: (v: string) => void;
  placeholder?: string;
}

const SearchBox: FC<SearchBoxProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { label, onSearch, onChange, value, placeholder } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <FieldLabel>{label}</FieldLabel>
      <Search
        onChange={(e) => onChange && onChange(e.target.value)}
        value={value}
        onSearch={onSearch}
        className={placeholder}
      ></Search>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default SearchBox;
