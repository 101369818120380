import React, { FC } from "react";

import { LoadingOutlined } from "@ant-design/icons";

// ----------------------------------------------------------------------------

interface AppButtonProps {
  className?: string;
  onClick?;
  role?: "primary" | "secondary" | "delete" | "success";
  type?: "button" | "submit";
  loading?: boolean;
}

const AppButton: FC<AppButtonProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { type, role, onClick } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  const getClass = () => {
    switch (role) {
      case "primary":
        return "bg-primary-500 hover:bg-primary-900 text-white";
      case "secondary":
        return "border-2 border-black text-black bg-white font-bold";
      case "delete":
        return "border-error text-error border-2 font-bold";
      case "success":
        return "border-success bg-success text-white border-2 font-bold";
      default:
        return "bg-primary-500 hover:bg-primary-900";
    }
  };

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <button
      type={type}
      className={[
        `${props.className} block w-full`,
        `transition-all h-[40px] rounded`,
        "flex flex-row items-center justify-center",
        getClass(),
      ].join(" ")}
      onClick={onClick}
    >
      {props.loading && (
        <div className="flex items-center justify-center mr-2">
          <LoadingOutlined />
        </div>
      )}

      {props.children}
    </button>
  );
};

// ----------------------------------------------------------------------------

export default AppButton;
