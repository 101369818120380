import React, { FC } from "react";

import { StandardSection, Task } from "../../../api/types";
import AppTextarea from "../../FormItems/AppTextarea";

// ----------------------------------------------------------------------------

interface OpenProps {
  className?: string;
  task: Task;
  getFieldProps: (field: string) => any;
  setValues: (
    changer: (prevValues: StandardSection) => StandardSection
  ) => void;
  index: number;
  exerciseIndex: number;
}

const Open: FC<OpenProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { getFieldProps, index, exerciseIndex } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const target = `exercises.${exerciseIndex}.tasks.${index}`;

  return (
    <div className={`${props.className}`}>
      <AppTextarea
        {...getFieldProps(`${target}.rightAnswer`)}
        label="Risposta consigliata"
        className="w-[500px]"
      />
    </div>
  );
};

// ----------------------------------------------------------------------------

export default Open;
