import { message } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../shared/AppState/auth";
import {
  deleteCustomSheet,
  getCustomSheets,
  getStandardSectionNames,
} from "../../../shared/api";
import { CustomSheet } from "../../../shared/api/types";
import AppTable from "../../../shared/components/AppTable";
import NewResourceButton from "../../../shared/components/NewResourceButton";
import PageTitle from "../../../shared/components/Typography/PageTitle";
import { idExtractor } from "../../../shared/toolkit";

// ----------------------------------------------------------------------------

interface CustomSheetsProps {
  className?: string;
}

const CustomSheets: FC<CustomSheetsProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------
  type Filters = { [key: string]: any };
  const { token } = useAuth();
  const [filters, setFilters] = useState<Filters>({});

  const { data: sheets, refetch } = useQuery<CustomSheet[]>(
    ["sectionNames", token],
    async () => await getCustomSheets(token, filters)
  );

  const navigate = useNavigate();

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  const handleEdit = (row: CustomSheet) => {
    const { _id } = row;
    navigate(`/schede/${_id}`);
  };

  const handleDelete = async (row: CustomSheet) => {
    const { _id } = row;
    try {
      await deleteCustomSheet(_id, token);
      refetch();
      message.success("Operazione completata");
    } catch {
      message.error("Operazione fallita");
    }
  };

  function toNewSection() {
    navigate("/schede/crea");
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <PageTitle>Schede</PageTitle>
      <NewResourceButton onClick={toNewSection} />
      <AppTable
        dataSource={sheets}
        columns={getColumns()}
        onEdit={handleEdit}
        onDelete={handleDelete}
        rowKey={(i) => idExtractor(i)}
      ></AppTable>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default CustomSheets;

function getColumns(): ColumnsType<CustomSheet> {
  return [
    { title: "Nome sezione", key: "name", dataIndex: "title" },
    {
      title: "Tipologia",
      key: "type",
      dataIndex: "type",
      render: (type) => (type === "normal" ? "Normale" : "Esame"),
    },
  ];
}
