import { message } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useState } from "react";
import { useQuery } from "react-query";

import { useAuth } from "../../../shared/AppState/auth";
import { createUserAsAdmin, getUsers, updateUser } from "../../../shared/api";
import { User } from "../../../shared/api/types";
import AppTable from "../../../shared/components/AppTable/index";
import NewResourceButton from "../../../shared/components/NewResourceButton";
import PageTitle from "../../../shared/components/Typography/PageTitle";
import { idExtractor } from "../../../shared/toolkit";
import UsersBottomSheet from "./UsersBottomSheet";
import UsersFilters from "./UsersFilters";

// ----------------------------------------------------------------------------

interface UsersProps {
  className?: string;
}

const Users: FC<UsersProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------
  const [filters, setFilters] = useState<{ [key: string]: any }>({
    activeStatus: 1,
  });

  const { token } = useAuth();
  const { data: users, refetch } = useQuery(
    ["users", filters, token],
    async () => await getUsers(token, filters)
  );

  const [selectedItem, setSelectedItem] = useState<User | null>(null);

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function handleConfirm(values: User) {
    const isEdit = !!values._id;
    try {
      if (isEdit) {
        await updateUser(values._id, values, token);
      } else {
        await createUserAsAdmin(values, token);
      }
      refetch();
      setSelectedItem(null);
      message.success("Operazione completata con successo");
    } catch (e) {
      message.error("Operaizone fallita");
    }
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <PageTitle>Utenti</PageTitle>
      <div
        className={[
          "flex flex-row items-center justify-between w-full mb-2",
        ].join(" ")}
      >
        <NewResourceButton
          onClick={() => setSelectedItem({ email: "", role: "user" })}
        />
        <UsersFilters onFilter={setFilters} />
      </div>
      <AppTable<User>
        className=""
        dataSource={users}
        rowKey={(i) => idExtractor(i)}
        columns={getColumns()}
        onEdit={(row) => setSelectedItem(row)}
      />
      <UsersBottomSheet
        defaultValues={selectedItem}
        onConfirm={handleConfirm}
        open={!!selectedItem}
        onClose={() => setSelectedItem(null)}
      />
    </div>
  );
};

// ----------------------------------------------------------------------------

export default Users;

const getColumns: () => ColumnsType<any> = () => {
  return [
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Ruolo",
      key: "role",
      dataIndex: "role",
      render: (role) => getRoleName(role),
    },
    {
      title: "Stato",
      key: "status",
      render: (row) => {
        return row?.emailValidationToken?.activated ? (
          <span className="text-success font-bold">Attivo</span>
        ) : (
          <span className="text-error font-bold">In attesa di conferma</span>
        );
      },
    },
  ];
};

const getRoleName = (role) => {
  switch (role) {
    case "tutor":
      return "Tutor";
    case "admin":
      return "Amministratore";
    case "user":
      return "Utente";
    default:
      return "Non noto";
  }
};
