import React, { FC } from "react";

import { StandardSection } from "../../../../../shared/api/types";
import AppInput from "../../../../../shared/components/FormItems/AppInput";
import AppTextarea from "../../../../../shared/components/FormItems/AppTextarea";

// ----------------------------------------------------------------------------

interface StandardSectionGeneralProps {
  className?: string;
  values: StandardSection;
  getFieldProps: (field: string) => any;
}

const StandardSectionGeneral: FC<StandardSectionGeneralProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { getFieldProps } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <AppInput
        {...getFieldProps("title")}
        className="mb-2 w-300"
        label="Nome sezione"
      />
      <AppTextarea
        {...getFieldProps("description")}
        className="mb-2 w-300"
        label="Descrizione"
      />
    </div>
  );
};

// ----------------------------------------------------------------------------

export default StandardSectionGeneral;
