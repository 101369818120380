import React, { FC, useMemo } from "react";
import { useQuery } from "react-query";

import { useAuth } from "../../../../shared/AppState/auth";
import { getCustomSheets, getUsers } from "../../../../shared/api";
import { CustomSheet, User } from "../../../../shared/api/types";
import AppDatePicker from "../../../../shared/components/AppDatePicker";
import AppSelect from "../../../../shared/components/FormItems/AppSelect";

// ----------------------------------------------------------------------------

export interface SubmissionQueryFilters {
  after?: string;
  before?: string;
  type: string;
  user?: string;
  sheet?: string;
}

interface SubmissonFiltersProps {
  className?: string;
  filters: SubmissionQueryFilters;
  onFilter: (filters: SubmissionQueryFilters) => void;
}

const SubmissionFilters: FC<SubmissonFiltersProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { filters, onFilter } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const { token } = useAuth();
  const { data: users } = useQuery<User[]>(
    ["users", token],
    async () => await getUsers(token, {})
  );

  const { data: sheets } = useQuery<CustomSheet[]>(
    ["sheets", token],
    async () => await getCustomSheets(token, {})
  );

  const userOptions = useMemo(() => {
    return (
      users &&
      users.map((user) => ({ label: user.email, value: user._id as string }))
    );
  }, [users]);

  const sheetOptions = useMemo(() => {
    return (
      sheets?.map((sheet) => ({
        label: sheet.title,
        value: sheet._id as string,
      })) ?? []
    );
  }, [sheets]);

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function updateField(field) {
    return (value) => {
      console.log(value);

      onFilter({ ...filters, [field]: value });
    };
  }

  function handleSearch(input, option) {
    const { label } = option;
    return new RegExp(input).test(label);
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className} `}>
      <div className="flex flex-row mb-2">
        <AppDatePicker
          label="A partire da"
          value={filters.after}
          onChange={updateField("after")}
          inputClass="w-[150px] mr-2"
        />
        <AppDatePicker
          label="Fino a"
          value={filters.before}
          onChange={updateField("before")}
          inputClass="w-[150px] mr-2"
        />
        <AppSelect
          label="Tipologia"
          value={filters.type}
          onChange={updateField("type")}
          options={[
            { label: "Tutti", value: "off" },
            { label: "Eseciziario", value: "standard" },
            { label: "Scheda", value: "sheet" },
          ]}
          className="!w-[150px] mr-2"
        />
      </div>
      <div className="flex flex-row">
        <AppSelect
          value={filters.user}
          onChange={updateField("user")}
          options={userOptions ?? []}
          label="Utente"
          className="!w-[200px] mr-2"
          filterOption={handleSearch}
          allowClear
        />
        <AppSelect
          value={filters.sheet}
          onChange={updateField("sheet")}
          options={sheetOptions ?? []}
          label="Scheda"
          className="!w-[200px] mr-2"
          filterOption={handleSearch}
          allowClear
        />
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default SubmissionFilters;
