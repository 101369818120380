import React, { FC } from "react";

import { StandardSection, Task } from "../../../api/types";
import AppInput from "../../FormItems/AppInput";

// ----------------------------------------------------------------------------

interface SatzfelderProps {
  className?: string;
  task: Task;
  getFieldProps: (field: string) => any;
  setValues: (
    changer: (prevValues: StandardSection) => StandardSection
  ) => void;
  index: number;
  exerciseIndex: number;
  updateTask: (task: Task) => void;
}

const Satzfelder: FC<SatzfelderProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { getFieldProps, index, exerciseIndex } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  const target = `exercises.${exerciseIndex}.tasks.${index}`;

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <Input
        getFieldProps={getFieldProps}
        field={`${target}.rightAnswer.0`}
        label={"VVF"}
      />
      <Input
        getFieldProps={getFieldProps}
        field={`${target}.rightAnswer.1`}
        label={"VF"}
      />
      <Input
        getFieldProps={getFieldProps}
        field={`${target}.rightAnswer.2`}
        label={"RSK"}
      />
      <Input
        getFieldProps={getFieldProps}
        field={`${target}.rightAnswer.3`}
        label={"MF"}
      />
      <Input
        getFieldProps={getFieldProps}
        field={`${target}.rightAnswer.4`}
        label={"LSK"}
      />
      <Input
        getFieldProps={getFieldProps}
        field={`${target}.rightAnswer.5`}
        label={"NF"}
      />
    </div>
  );
};

// ----------------------------------------------------------------------------

export default Satzfelder;

const Input = ({ field, label, getFieldProps }) => {
  return (
    <AppInput
      key={field}
      {...getFieldProps(field)}
      label={label}
      className="mb-1"
    />
  );
};
