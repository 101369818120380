import create from "zustand";
import { persist } from "zustand/middleware";

interface AuthState {
  user?: any;
  token?: string;
  login: (user, token) => void;
  logout: () => void;
}

export const useAuth = create<AuthState>(
  persist(
    (set) => {
      return {
        user: undefined,
        token: undefined,
        login: (user, token) => set({ user, token }),
        logout: () => set({ user: undefined, token: undefined }),
      };
    },
    { name: "auth", getStorage: () => localStorage }
  )
);
