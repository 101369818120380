import { Input } from "antd";
import React, { FC } from "react";

import FieldLabel from "../FieldLabel";
import ValidationMessage from "../ValidationMessage";

// ----------------------------------------------------------------------------

interface AppInputProps {
  className?: string;
  label?: string;
  onChange?: (str: string) => void;
  placeholder?: string;
  onBlur?: () => void;
  error?: string;
  type?: string;
  value: string;
}

const AppInput: FC<AppInputProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, label, onChange, error, ...restProps } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${className}`}>
      <FieldLabel className="">{label}</FieldLabel>
      <Input
        autoComplete="no"
        className="w-full block"
        {...restProps}
        onChange={(e) => onChange && onChange(e?.target?.value)}
      />
      <ValidationMessage className="">{error}</ValidationMessage>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default AppInput;
