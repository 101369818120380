import { ColumnsType } from "antd/lib/table";
import React, { FC } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../shared/AppState/auth";
import { getStandardSectionNames } from "../../../shared/api";
import AppTable from "../../../shared/components/AppTable";
import NewResourceButton from "../../../shared/components/NewResourceButton";
import PageTitle from "../../../shared/components/Typography/PageTitle";
import { idExtractor } from "../../../shared/toolkit";

// ----------------------------------------------------------------------------

interface StandardSectionsProps {
  className?: string;
}

interface SectionName {
  title: string;
  _id: string;
}

type SectionNames = SectionName[];

const StandardSections: FC<StandardSectionsProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const { token } = useAuth();
  const { data: sections } = useQuery<SectionNames>(
    ["sectionNames", token],
    async () => await getStandardSectionNames(token)
  );

  const navigate = useNavigate();

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  const handleEdit = (row: SectionName) => {
    const { _id } = row;
    navigate(`/eserciziario/${_id}`);
  };

  const handleDelete = (row: SectionName) => {};

  function toNewSection() {
    navigate("/eserciziario/crea");
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <PageTitle>Eserciziario</PageTitle>
      <NewResourceButton onClick={toNewSection} />
      <AppTable
        dataSource={sections}
        columns={getColumns()}
        onEdit={handleEdit}
        onDelete={handleDelete}
        rowKey={(i) => idExtractor(i)}
      ></AppTable>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default StandardSections;

function getColumns(): ColumnsType<SectionName> {
  return [{ title: "Nome sezione", key: "name", dataIndex: "title" }];
}
