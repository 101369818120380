import urljoin from "url-join";

import {
  executeDELETERequest,
  executeFormDateRequest,
  executeGETRequest,
  executePOSTRequest,
  executePUTRequest,
} from "./base";

export const getBaseUrl = () =>
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://elt1-api.aronwinkler.com";
export const getFrontEndUrl = () =>
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/#"
    : "https://elt1.aronwinkler.com/#";

// -------------------------------------
// Auth
// -------------------------------------

export const login = async (email, password) => {
  const url = urljoin(getBaseUrl(), "/auth/login");
  return await executePOSTRequest(url, null, { email, password });
};

// -------------------------------------
// Users
// -------------------------------------

export const getUsers = async (token, filters) => {
  const url = urljoin(getBaseUrl(), "/users");
  return await executeGETRequest(url, token, filters);
};

export const updateUser = async (id, body, token) => {
  const url = urljoin(getBaseUrl(), `/users/${id}`);
  return await executePUTRequest(url, token, body);
};

export const createUserAsAdmin = async (body, token) => {
  const url = urljoin(getBaseUrl(), "/users/create-as-admin");
  return await executePOSTRequest(url, token, body);
};

// -------------------------------------
// Standard sections
// -------------------------------------

export const getStandardSectionNames = async (token) => {
  const url = urljoin(getBaseUrl(), "/standard-sections/names");
  return await executeGETRequest(url, token);
};

export const getSheet = async (sections, perSection, token) => {
  const url = urljoin(getBaseUrl(), "/standard-sections/sheet");
  return await executeGETRequest(url, token, {
    "include-sections": sections,
    "exe-per-section": perSection,
  });
};

export const getSection = async (id, token) => {
  const url = urljoin(getBaseUrl(), `/standard-sections/${id}`);
  return await executeGETRequest(url, token);
};

export const updateStandardSection = async (id, body, token) => {
  const url = urljoin(getBaseUrl(), `/standard-sections/${id}`);
  return await executePUTRequest(url, token, body);
};

export const createStandardSection = async (body, token) => {
  const url = urljoin(getBaseUrl(), `/standard-sections`);
  return await executePOSTRequest(url, token, body);
};

// -------------------------------------
// Submissions
// -------------------------------------

export const getCustomSheets = (token, filters) => {
  const url = urljoin(getBaseUrl(), "/sheets");
  return executeGETRequest(url, token, filters);
};

export const getCustomSheet = (id, token) => {
  const url = urljoin(getBaseUrl(), `/sheets/${id}`);
  return executeGETRequest(url, token);
};

export const createCustomSheet = (body, token) => {
  const url = urljoin(getBaseUrl(), `/sheets`);
  return executePOSTRequest(url, token, body);
};

export const updateCustomSheet = (id, body, token) => {
  const url = urljoin(getBaseUrl(), `/sheets/${id}`);
  return executePUTRequest(url, token, body);
};

export const deleteCustomSheet = (id, token) => {
  const url = urljoin(getBaseUrl(), `/sheets/${id}`);
  return executeDELETERequest(url, token);
};

// -------------------------------------
// Submissions
// -------------------------------------

export const createSubmission = async (data, token) => {
  const url = urljoin(getBaseUrl(), "/submissions");
  return await executePOSTRequest(url, token, data);
};

export const getSubmissions = async (token, filters = {}, page) => {
  const url = urljoin(getBaseUrl(), "/submissions");
  return await executeGETRequest(url, token, { ...filters, page, limit: 20 });
};

export const getSubmission = async (id, token) => {
  const url = urljoin(getBaseUrl(), `/submissions/${id}`);
  return await executeGETRequest(url, token);
};

// -------------------------------------
// Uploads
// -------------------------------------

export const uploadFile = async (file, token) => {
  const url = urljoin(getBaseUrl(), "/uploads");
  const body = { file };

  return await executeFormDateRequest(url, token, body);
};
