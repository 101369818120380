import { message } from "antd";
import React, { FC, useEffect } from "react";
import * as Yup from "yup";

import { User } from "../../../../shared/api/types";
import BottomSheet from "../../../../shared/components/BottomSheet";
import AppInput from "../../../../shared/components/FormItems/AppInput";
import AppSelect from "../../../../shared/components/FormItems/AppSelect";
import useFormHandler from "../../../../shared/hooks/useFormHandler";

// ----------------------------------------------------------------------------

interface UsersBottomSheetProps {
  className?: string;
  onConfirm: (values: User) => void;
  onClose: () => void;
  open: boolean;
  defaultValues: User | null;
}

const UsersBottomSheet: FC<UsersBottomSheetProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { open, onClose, onConfirm, defaultValues } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------
  const msg = "Campo obbligatorio";
  const initialValues: User = { email: "", role: "user" };
  const validationSchema = Yup.object().shape({
    _id: Yup.string(),
    email: Yup.string().when("_id", {
      is: (id) => !id,
      then: Yup.string().required(msg),
      otherwise: Yup.string(),
    }),
    password: Yup.string().when("_id", {
      is: (id) => !id,
      then: Yup.string().required(msg),
      otherwise: Yup.string(),
    }),
    role: Yup.string().required(msg),
  });

  const { getFieldProps, handleSubmit, bulkChange, values, reset } =
    useFormHandler<User>({
      initialValues,
      validationSchema,
      onSubmit: handleConfirm,
      onSubmitWithErrorsMessage: () =>
        message.error("Ci sono errori nella compilazione"),
    });

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    defaultValues && bulkChange({ ...values, ...defaultValues });
  }, [defaultValues]);

  useEffect(() => {
    !open && reset();
  }, [open]);

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleConfirm(values: User) {
    onConfirm && onConfirm(values);
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const isEdit = !!defaultValues?._id;

  return (
    <BottomSheet
      title={isEdit ? "Modifica utente" : "Crea nuovo utente"}
      open={open}
      onConfirm={handleSubmit}
      onClose={onClose}
      className={`${props.className}`}
    >
      {!isEdit && (
        <>
          <AppInput
            label="Indirizzo e-mail"
            className="w-[300px] mb-3"
            {...getFieldProps("email")}
          />
          <AppInput
            className="w-[300px] mb-3"
            label="Password"
            {...getFieldProps("password")}
          />
        </>
      )}
      <AppSelect
        label="Ruolo"
        className="w-[300px] mb-3"
        options={[
          { label: "Amministratore", value: "admin" },
          { label: "Tutor", value: "tutor" },
          { label: "Utente", value: "user" },
        ]}
        {...getFieldProps("role")}
      />
    </BottomSheet>
  );
};

// ----------------------------------------------------------------------------

export default UsersBottomSheet;
