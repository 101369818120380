import { message } from "antd";
import { FormikConfig, useFormik } from "formik";
import { useEffect } from "react";

interface Params<T> extends FormikConfig<T> {
  onSubmitWithErrorsMessage: () => void;
}

export default function useFormHandler<T>(params: Params<T>) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    initialTouched,
    onSubmitWithErrorsMessage,
  } = params;

  const formik = useFormik<T>({
    initialValues,
    validationSchema,
    onSubmit,
    initialTouched,
    validateOnMount: true,
  });

  useEffect(() => {
    if (formik.submitCount && !formik.isValid) {
      if (onSubmitWithErrorsMessage) {
        onSubmitWithErrorsMessage();
      } else {
        message.error("Ci sono errori nei campi compilati");
      }
      console.log("ERRORS", formik.errors);
    }
    // eslint-disable-next-line
  }, [formik.submitCount]);

  return mapFormikProps(formik);
}

function mapFormikProps(formikProps) {
  return {
    values: formikProps.values,
    onChange: (field) => (value) => formikProps.setFieldValue(field, value),
    bulkChange: formikProps.setValues,
    errors: { ...formikProps.errors },
    touched: formikProps.touched,
    onBlur: (field) => () => formikProps.setFieldTouched(field),
    setTouched: formikProps.setTouched,
    isValid: formikProps?.isValid,
    handleSubmit: formikProps.handleSubmit,
    reset: formikProps.resetForm,

    getFieldProps: (field) => {
      const rawProps = formikProps?.getFieldProps(field);
      const rawMeta = formikProps?.getFieldMeta(field);
      const onChange = (value) => formikProps.setFieldValue(field, value);
      const onBlur = () => formikProps.setFieldTouched(field);
      const error = rawMeta?.touched && rawMeta?.error;
      const value = rawProps?.value;

      return { onChange, onBlur, error, value };
    },
  };
}
