import { Table, TableProps } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useMemo } from "react";

import TableActions from "../TableActions";

// ----------------------------------------------------------------------------

interface AppTableProps<T> extends TableProps<T> {
  className?: string;
  children?: any;
  columns: ColumnsType<T>;
  onEdit?: (row: T, index: number) => void;
  onDelete?: (row: T, index: number) => void;
  onView?: (row: T, index: number) => void;
}

function AppTable<T extends object>(props: AppTableProps<T>) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { columns, onEdit, onDelete, onView } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const updatedColumns = useMemo(() => {
    if (onDelete || onEdit || onView) {
      return [
        ...columns,
        {
          title: "Azioni",
          key: "actions",
          render: (text, row, index) => (
            <TableActions<T>
              row={row}
              onEdit={onEdit && (() => onEdit(row, index))}
              onDelete={onDelete && (() => onDelete(row, index))}
              onView={onView && (() => onView(row, index))}
            />
          ),
        },
      ];
    }
    return columns;
  }, [columns]);

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <Table<T>
      {...props}
      className={`${props.className}`}
      columns={updatedColumns}
    ></Table>
  );
}

// ----------------------------------------------------------------------------

export default AppTable;
