import { Input } from "antd";
import React, { FC } from "react";

import FieldLabel from "../FieldLabel";
import ValidationMessage from "../ValidationMessage";

const { TextArea } = Input;

// ----------------------------------------------------------------------------

interface AppTextareaProps {
  className?: string;
  label: string;

  value: string;
  placeholder: string;
  onBlur: () => void;
  onChange: (v: string) => void;
  error: string;
}

const AppTextarea: FC<AppTextareaProps> = (props) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { label, error, ...restProps } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${props.className}`}>
      <FieldLabel>{label}</FieldLabel>
      <TextArea
        {...restProps}
        onChange={(e) => props.onChange(e.target.value)}
        className="w-full"
      ></TextArea>
      <ValidationMessage>{error}</ValidationMessage>
    </div>
  );
};

// ----------------------------------------------------------------------------

export default AppTextarea;
